var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          class: _vm.$vuetify.breakpoint.smAndDown ? "mb-12" : null,
          attrs: { elevation: "0", width: "100%" },
        },
        [
          _c(
            "v-card-title",
            { staticClass: "pr-0 py-2" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "span",
                    {
                      staticClass: "font-weight-medium my-auto",
                      class: _vm.$vuetify.breakpoint.mdAndUp
                        ? "ml-4 text-h5"
                        : "ml-1 text-h6",
                    },
                    [
                      _vm._v(_vm._s(_vm.$t("pendingApproval")) + " "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-3",
                          attrs: {
                            elevation: "0",
                            fab: "",
                            outlined: "",
                            width: "25",
                            height: "25",
                            color: "primary",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.totalPendingApproval))]
                      ),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    {
                      class: !_vm.$vuetify.breakpoint.mdAndUp
                        ? "pl-0 pr-4"
                        : "pr-6 px-10 py-4",
                      attrs: { cols: "4", lg: "2", md: "2", xl: "2" },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.statusOptions,
                          "menu-props": { bottom: true, offsetY: true },
                          outlined: "",
                          "hide-details": "",
                          flat: "",
                          solo: "",
                          dense: "",
                          height: "30",
                          required: "",
                          label: "",
                          "aria-label": _vm.selectedStatus,
                        },
                        on: { change: _vm.filterApprovals },
                        model: {
                          value: _vm.selectedStatus,
                          callback: function ($$v) {
                            _vm.selectedStatus = $$v
                          },
                          expression: "selectedStatus",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      class: !_vm.$vuetify.breakpoint.mdAndUp
                        ? "pl-0 pr-4"
                        : "pr-6 py-4",
                      attrs: { cols: "12", lg: "4", md: "4", xl: "4" },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "rounded-lg",
                        attrs: {
                          "prepend-inner-icon": "mdi-magnify",
                          label: _vm.$t("search"),
                          "single-line": "",
                          "hide-details": "",
                          flat: "",
                          solo: "",
                          "background-color": "grey lighten-4",
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "elevation-0 pa-0 awaiting-table",
            class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-2" : null,
            attrs: {
              headers: _vm.headers,
              items: _vm.approvalData,
              "items-per-page": !_vm.$vuetify.breakpoint.mdAndUp ? -1 : 15,
              search: _vm.search,
              "item-key": "email",
              color: "primary",
              "checkbox-color": "primary",
              "hide-default-footer": !_vm.$vuetify.breakpoint.mdAndUp,
              loading:
                _vm.approvalData.length == 0 &&
                !_vm.isTableEmpyt &&
                _vm.selectedStatus === "All",
              "loading-text": "Loading... Please wait",
              "mobile-breakpoint": "950",
              "disable-sort": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.center_ticket_id",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-row",
                        {
                          staticClass: "text-subtitle-2 font-weight-bold ma-0",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "primary--text text-decoration-none",
                            },
                            [_vm._v(" " + _vm._s(item.center_ticket_id))]
                          ),
                        ]
                      ),
                      _c("v-row", { staticClass: "ma-0" }, [
                        _vm._v(" " + _vm._s(item.email)),
                      ]),
                    ]
                  },
                },
                {
                  key: "item.edit",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2",
                          attrs: { "aria-label": "Edit" },
                          on: {
                            click: function ($event) {
                              return _vm.editItem(item)
                            },
                          },
                        },
                        [_vm._v(" mdi-pencil-outline ")]
                      ),
                    ]
                  },
                },
                {
                  key: "item.priority",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "div",
                        { staticClass: "nowrap" },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                small: "",
                                color: _vm.priorityIconColor(item.priority),
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.priorityIcon(item.priority)) +
                                  " "
                              ),
                            ]
                          ),
                          _vm._v(" " + _vm._s(item.priority) + " "),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "item.status",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-chip",
                        {
                          staticClass: "white--text",
                          attrs: {
                            color: _vm.getColor(item.status),
                            small: "",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.status === "pending"
                                  ? "Pending Approval"
                                  : _vm.$t(item.status)
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  },
                },
                {
                  key: "item.editRow",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled: item.status !== "rejected",
                            plain: "",
                            small: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editItem(item)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-pencil-outline")])],
                        1
                      ),
                    ]
                  },
                },
                !_vm.$vuetify.breakpoint.mdAndUp
                  ? {
                      key: "body",
                      fn: function (ref) {
                        var items = ref.items
                        return [
                          _c(
                            "tbody",
                            _vm._l(items, function (item) {
                              return _c(
                                "tr",
                                { key: item.email, staticClass: "pb-4" },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      attrs: { height: "300", elevation: "0" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "pr-0" },
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass:
                                                    "white--text mr-4",
                                                  attrs: {
                                                    "x-small": "",
                                                    color: _vm.getColor(
                                                      item.status
                                                    ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.status ===
                                                          "pending"
                                                          ? "Pending Approval"
                                                          : _vm.$t(item.status)
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("Date Review")) +
                                                  ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 font-weight-medium",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(item.received_date)
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("approver")) + ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 font-weight-medium",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.approver)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("comments")) + ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 font-weight-medium",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.comments)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("priority")) + ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 font-weight-medium",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.priority)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("type")) + ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 font-weight-medium",
                                            },
                                            [_vm._v(" " + _vm._s(item.type))]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("center")) + ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 font-weight-medium",
                                            },
                                            [_vm._v(" " + _vm._s(item.center))]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("address")) + ":"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-0 font-weight-medium",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.street) +
                                                  "," +
                                                  _vm._s(item.place) +
                                                  "," +
                                                  _vm._s(item.county) +
                                                  "," +
                                                  _vm._s(item.state)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "ml-1 pt-4 mb-4" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pa-0",
                                              attrs: { cols: "3" },
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    disabled:
                                                      item.status !==
                                                      "rejected",
                                                    plain: "",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editItem(item)
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      "mdi-pencil-outline"
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      },
                    }
                  : null,
              ],
              null,
              true
            ),
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }